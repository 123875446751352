import Logo from "../images/bll-logo-no-background-white.png";

export default function Header() {
  return (
    <header className="brand-primary-bg">
      <nav className="border-gray-200">
        <div className="flex flex-wrap items-center mx-auto max-w-screen-xl p-4 justify-center md:justify-between">
          <div className="flex items-center">
            <img
              src={Logo}
              width={300}
              height={30}
              alt="Bolton Listening Lounge"
            />
          </div>
          <div className="flex items-center">
            <a
              href="tel:01204 917739"
              className="mr-6 text-sm text-white hover:underline"
            >
              01204 917739
            </a>
            <a
              href="mailto:boltonll@family-action.org.uk"
              className="text-sm text-white hover:underline"
            >
              boltonll@family-action.org.uk
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
}
