import {
  ClockIcon,
  MapPinIcon,
  PhoneIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";

const features = [
  // {
  //   name: "Do I need a referral?",
  //   generateDescription: () =>
  //     "No. You can ring or turn up if you want unannounced although we are happy to take referrals.",
  //   icon: QuestionMarkCircleIcon,
  // },
  // {
  //   name: "Where you can visit us",
  //   generateDescription: () => {
  //     return (
  //       <>
  //         <p>
  //           You can drop it at our{" "}
  //           <a
  //             href="https://boltonymca.co.uk/about-us/"
  //             target="_blank"
  //             className="brand-primary"
  //             rel='noreferrer'
  //           >
  //             YMCA base
  //           </a>{" "}
  //           on Deansgate ONLY during our opening hours. You do not need to make
  //           an appointment.
  //         </p>
  //       </>
  //     );
  //   },
  //   icon: MapPinIcon,
  // },
  {
    name: "When we are open",
    generateDescription: () =>
      "We are open from 15:00 to 22:00 everyday including weekends and bank holidays",
    icon: ClockIcon,
  },
  {
    name: "How to contact us",
    generateDescription: () => {
      return (
        <>
          <p>
            Phone us on{" "}
            <a href="te:01204917739" className="brand-primary">
              01204 917739
            </a>{" "}
            or email us at{" "}
            <a
              href="mailto:boltonll@family-action.org.uk"
              className="brand-primary"
            >
              boltonll@family-action.org.uk
            </a>
          </p>
        </>
      );
    },
    icon: PhoneIcon,
  },
];

export default function Features() {
  return (
    <>
      <div className="bg-white px-6 py-12 sm:py-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <h2 className="text-base font-semibold leading-7 brand-primary">
              Helpful Information
            </h2>
            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Frequently Asked Questions
            </h2>
            <dl className="grid mt-6 max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg brand-primary-bg">
                      <feature.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  {feature.generateDescription()}
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <div className="bg-white py-24 sm:py-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl">
            <h2 className="text-base font-semibold leading-7 brand-primary">
              Non Emergency Contacts
            </h2>
            <h3 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Who else can you contact
            </h3>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              If it is not an emergency but you require health advice, visit{" "}
              <a
                href="https://111.nhs.uk/"
                target="_blank"
                className="brand-primary"
                rel='noreferrer'
              >
                NHS 111 online
              </a>{" "}
              or call 111.
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              If you need non-emergency access to the police call 101.
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Visit your local Accident & Emergency department if you need
              urgent physical health care or an urgent mental health assessment.
              You can ring the 24/7 Mental Health Crisis Helpline for all ages
              on{" "}
              <a href="tel:08009530285" className="brand-primary">
                0800 953 0285
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
