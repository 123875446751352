import MhISTLogo from '../images/mhist-logo.png';
import GMMHLogo from '../images/gmmh-logo.png';
import FamilyActionBandLogo from '../images/family-action-band-logo.jpeg';

export default function Partners() {
  return (
    <div className="bg-white px-6 py-12 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
          The Bolton Listening Lounge is provided as a partnership between the
          staff of
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-3 items-center gap-x-8 gap-y-10 lg:mx-0 lg:max-w-none grid-cols-3">
          <a href="https://mhist.co.uk" target="_blank" rel="noreferrer">
            <img
              src={MhISTLogo}
              className="col-span-4 max-h-24 w-full object-contain col-span-1"
              width={300}
              height={100}
              alt="MhIST Bolton"
            ></img>
          </a>
          <a
            href="https://www.family-action.org.uk/what-we-do/adult-mental-health-and-wellbeing/band/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={FamilyActionBandLogo}
              className="col-span-4 max-h-24 w-full object-contain col-span-1"
              width={300}
              height={100}
              alt="Family Action | Band Bolton"
            ></img>
          </a>
          <a href="https://www.gmmh.nhs.uk/" target="_blank" rel="noreferrer">
            <img
              src={GMMHLogo}
              className="col-span-4 max-h-24 w-full object-contain col-span-1"
              width={300}
              height={100}
              alt="Greater Manchester Mental Health | NHS"
            ></img>
          </a>
        </div>
      </div>
    </div>
  );
}
