import Logo from "../images/bll-logo-no-background-black.png";

export default function Footer() {
  return (
    <footer className="bg-white rounded-lg m-4">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="flex items-center justify-center">
          <img
            src={Logo}
            width={300}
            height={30}
            alt=""
          />
        </div>
        <hr className="my-6 border-gray-200 mx-auto lg:my-8" />
        <span className="block text-sm text-gray-500 text-center">
          © 2023{" "}
          <a href="/" className="hover:underline">
            Bolton Listening Lounge
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
}
