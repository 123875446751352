export default function About() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-12 sm:py-24 lg:px-8">
        <div className="relative isolate overflow-hidden brand-primary-bg px-6 shadow-2xl sm:rounded-3xl sm:px-16 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle
              cx={512}
              cy={512}
              r={512}
              fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#BDC2BF" />
                <stop offset={1} stopColor="#BDC2BF" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto text-center mx-0 flex-auto py-16 lg:py-32 text-left">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Please remember this is not an emergency service
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              If you or someone else is in immediate danger, call 999. You
              should attend your local NHS Accident & Emergency if you need
              urgent physical health care or require an urgent mental health
              assessment.
            </p>  
          </div>
        </div>
      </div>
    </div>
  );
}
