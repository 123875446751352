import Header from './components/Header';
import Section from './components/Section';
import Features from './components/Features';
import About from './components/About';
import Partners from './components/Partners';
import Footer from './components/Footer';
import ReactGA from "react-ga4";

ReactGA.initialize("G-JW20WWP0LJ");
// import ComingSoon from './components/ComingSoon';

function App() {
  return (
    <>
      <Header></Header>
      <Section></Section>
      <Features></Features>
      <About></About>
      <Partners></Partners>
      <Footer></Footer>
      {/* <ComingSoon></ComingSoon> */}
    </>
  );
}

export default App;
