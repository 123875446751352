import LoveSupport from "../images/love-support.jpg";

export default function Section() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-12 sm:py-24 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
          />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <h1 className="mt-2 text-3xl font-bold text-gray-900 sm:text-4xl">
                What is the listening lounge
              </h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                We offer a warm welcome and non-judgmental support to local
                people looking for a quick non-clinical response to help with
                their current mood or unhelpful emotions. A key purpose of this
                service is to offer people different choices to managing their
                mental health and wellbeing, helping them avoid unnecessary
                visits to accident and emergency and other urgent care services.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            alt="Mental Health Background"
            src={LoveSupport}
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
          ></img>
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                The Bolton Listening Lounge provides a safe space for people who
                are experiencing mental distress or feel they are going through
                a personal crisis. Our service staff can support people with low
                level calming interventions and make referrals to a variety of
                supportive services. Our navigator will be able to link you in
                with local services that can help and it won’t just be
                signposting with a number to ring. Our advocate will be able to
                help with the practical difficulties in life that make your
                mental health worse.
              </p>
              <p className="mt-8">
                The Bolton Listening Lounge has strong links to support groups,
                organisations and other opportunities in the community which
                support people in building social networks and develop essential
                coping skills to reduce poor mental wellbeing in the future.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
